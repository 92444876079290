<template>
  <SupplierDetailPage
    product_router="EndUserGiftCardProduct"
    page_title="Gift card"
  ></SupplierDetailPage>
</template>

<script>
import SupplierDetailPage from '@/components/endUser/suppliersPages/SupplierDetailPage.vue';

export default {
  name: 'EndUserGiftCardSupplier',
  components: {
    SupplierDetailPage
  }
}
</script>
